import {appEvents} from 'core-application';

var _options = {
		initialContentAreaSelector: '.nm-wrapper-content .nm-area-content:first-child',
		navigationSelector: '.nm-navigation-derivative-main-list',
		layerNavigationSelector: '.nm-navigation-derivative-sub-list',
		globalLayerNavigationSelector: '.nm-layer .nm-navigation-derivative-sub-list',
		hiddenNavItemClassName: 'nm-navigation-hidden-item',
		showMoreButtonClassName: 'nm-navigation-more-btn-show',
		setMoreButtonOrderClass: 'nm-navigation-more-btn-order',
		hasMoreButtonClassName: 'nm-has-more-btn',
		basketToggle: '#nm-basket-toggle',
		mainNavToggle: '#nm-navigation-toggle-btn',
		shoppingToolsToggle: '#nm-supportbar-toggle',
		shoppingToolsLabel: '.nm-supportbar-toggle-label',
		shoppingToolsNav: '.nm-supportbar-navigation',
		stckWidgetToggle: '#nm-matching-toggle',
		stckWidgetLabel: '.nm-matching-toggle-label',
		sSelectorNavWrap: '.nm-nav-wrap',
		sClassLoaded: 'nm-nav-loaded',
		sClassStockCar: '.sc-module-main-navigation',
		mobileWidth: 768
	},
	// private methods
	__ = {
		isStockCar: false
	},
	// public api
	exports = {
		__: __
	};

__.showMoreButton = function(domElement, navClass) {
	var moreButton = domElement.find(navClass + ' > li:first-child'),
		navList = domElement.find(_options.layerNavigationSelector),
		$lastNavItem;

	if (moreButton.length > 0) {
		$lastNavItem = domElement.find(navClass + ' > li:last-child');

		if ($lastNavItem.hasClass('nm-summary-mobile-link')) {
			$lastNavItem = domElement.find(navClass + ' > li:nth-last-child(2)');
		}

		if (($lastNavItem.position().top > 0 || $lastNavItem.hasClass(_options.hiddenNavItemClassName)) && $lastNavItem.find('a').is(':visible')) {
			moreButton.addClass(_options.showMoreButtonClassName);
			moreButton.removeClass(_options.setMoreButtonOrderClass);
			navList.addClass(_options.hasMoreButtonClassName);

			jQuery(domElement.find(navClass + ' > li:not(.nm-navigation-hidden-item)').get()).each(function() {
				if (jQuery(this).find('a').length && jQuery(this).find('a').position().top > 0 && !jQuery(this).find('a').hasClass(_options.showMoreButtonClassName)) {
					jQuery(this).addClass(_options.hiddenNavItemClassName);
				}
			});
			moreButton.addClass(_options.setMoreButtonOrderClass);
		}
		else {
			moreButton.removeClass(_options.showMoreButtonClassName);
			navList.removeClass(_options.hasMoreButtonClassName);
		}
	}
	jQuery(_options.sSelectorNavWrap).addClass(_options.sClassLoaded);
};

__.setHiddenNavItems = function(domElement, navClass) {
	if (domElement.find(navClass).length > 0 && domElement.find(navClass + ' > li').length > 0) {
		domElement.find(navClass + ' > li').removeClass(_options.hiddenNavItemClassName);
		jQuery(domElement.find(navClass + ' > li').get().reverse()).each(function() {
			if (jQuery(this).find('a').length && jQuery(this).find('a').position().top > 0) {
				jQuery(this).addClass(_options.hiddenNavItemClassName);
			}
			else {
				return false;
			}
		});
	}
	__.showMoreButton(domElement, navClass);
};

__.initNavToggles = function(/* event */) {
	var shoppingToolLabel, shoppingToolNav;
	__.legacyEventBus.on('change', _options.basketToggle, __.handleToggleChange);

	/* check for stck widget */
	if (jQuery(_options.stckWidgetToggle).length) {
		__.legacyEventBus.on('change', _options.stckWidgetToggle, __.handleToggleChange);
	}

	if (jQuery(_options.shoppingToolsToggle).length) {
		__.legacyEventBus.on('change', _options.shoppingToolsToggle, __.handleToggleChange);
		if (jQuery(window).width() > _options.mobileWidth && !(('ontouchstart' in window) || navigator.msMaxTouchPoints)) {
			shoppingToolLabel = jQuery(_options.shoppingToolsLabel);
			shoppingToolNav = jQuery(_options.shoppingToolsNav);
			shoppingToolLabel.on('mouseover mouseout', __.handleShoppingToolsHover);
			shoppingToolNav.on('mouseover mouseout', __.handleShoppingToolsHover);
		}
	}
	__.legacyEventBus.on('change', _options.mainNavToggle, __.handleToggleChange);
};

__.handleShoppingToolsHover = function(event) {
	var shoppingToolToggle = jQuery(_options.shoppingToolsToggle);
	shoppingToolToggle.prop('checked', (event.type === 'mouseover'));
};

__.handleToggleChange = function(event) { // eslint-disable-line max-statements, complexity
	const basket = jQuery('.nm-basket');
	const hasBasket = basket.length > 0;
	const hasStckWidget = (jQuery(_options.stckWidgetToggle).length > 0);
	const hasShoppingTools = (jQuery(_options.shoppingToolsToggle).length > 0);

	if (jQuery(event.target).is(_options.basketToggle) && hasBasket) {
		basket.toggleClass('nm-basket-widget-is-open', jQuery(event.target).is(':checked'));
		jQuery('body').removeClass('nm-page-nav-is-open');
		if (jQuery(_options.mainNavToggle).is(':checked')) {
			jQuery(_options.mainNavToggle).prop('checked', false);
		}
		if (hasShoppingTools && jQuery(_options.shoppingToolsToggle).is(':checked')) {
			jQuery(_options.shoppingToolsToggle).prop('checked', false);
		}
		if (hasStckWidget && jQuery(_options.stckWidgetToggle).is(':checked')) {
			jQuery(_options.stckWidgetToggle).prop('checked', false);
		}
	}
	else if (jQuery(event.target).is(_options.mainNavToggle)) {
		jQuery('body').toggleClass('nm-page-nav-is-open', jQuery(event.target).is(':checked'));
		if (hasBasket) {
			basket.removeClass('nm-basket-widget-is-open');
		}
		if (jQuery(_options.basketToggle).is(':checked')) {
			jQuery(_options.basketToggle).prop('checked', false);
		}
		if (hasShoppingTools && jQuery(_options.shoppingToolsToggle).is(':checked')) {
			jQuery(_options.shoppingToolsToggle).prop('checked', false);
		}
		if (hasStckWidget && jQuery(_options.stckWidgetToggle).is(':checked')) {
			jQuery(_options.stckWidgetToggle).prop('checked', false);
		}
	}
	else if (jQuery(event.target).is(_options.shoppingToolsToggle)) {
		jQuery('body').removeClass('nm-page-nav-is-open');
		if (hasBasket) {
			basket.removeClass('nm-basket-widget-is-open');
		}
		if (jQuery(_options.basketToggle).is(':checked')) {
			jQuery(_options.basketToggle).prop('checked', false);
		}
		if (jQuery(_options.mainNavToggle).is(':checked')) {
			jQuery(_options.mainNavToggle).prop('checked', false);
		}
		if (hasStckWidget && jQuery(_options.stckWidgetToggle).is(':checked')) {
			jQuery(_options.stckWidgetToggle).prop('checked', false);
		}
	}
	else if (jQuery(event.target).is(_options.stckWidgetToggle)) {
		jQuery('body').removeClass('nm-page-nav-is-open');
		if (hasBasket) {
			basket.toggleClass('nm-basket-widget-is-open', jQuery(event.target).is(':checked'));
		}
		if (jQuery(_options.basketToggle).is(':checked')) {
			jQuery(_options.basketToggle).prop('checked', false);
		}
		if (jQuery(_options.mainNavToggle).is(':checked')) {
			jQuery(_options.mainNavToggle).prop('checked', false);
		}
		if (hasShoppingTools && jQuery(_options.shoppingToolsToggle).is(':checked')) {
			jQuery(_options.shoppingToolsToggle).prop('checked', false);
		}
	}
};

__.checkForMoreBtn = function(domElement) {
	var dataMainnavUrl, selector;
	__.setHiddenNavItems(domElement, _options.navigationSelector);
	dataMainnavUrl = domElement.find('.nm-nav-wrap').attr('data-mainnav');
	selector = (_options.navigationSelector + ' > li > a[data-href="' + dataMainnavUrl + '"] ~ .nm-navigation-sub-wrap-outer .nm-navigation-derivative-sub-list');
	__.setHiddenNavItems(domElement, selector);
};

__.checkForLayerMoreBtn = function(domElement) {
	var dataMainnavUrl, selector;
	__.setHiddenNavItems(domElement, _options.layerNavigationSelector);
	dataMainnavUrl = domElement.find('.nm-nav-wrap').attr('data-mainnav');
	selector = (_options.navigationSelector + ' > li > a[data-href="' + dataMainnavUrl + '"] ~ .nm-navigation-sub-wrap-outer .nm-navigation-derivative-sub-list');
	__.setHiddenNavItems(domElement, selector);
};

__.handlerPageLoaded = function(payload) {
	__.checkForMoreBtn(jQuery(payload.domElement));
	__.initNavToggles();
};

__.handlerLayerLoaded = function() {
	var layerDOM = jQuery('.nm-layer');
	__.checkForLayerMoreBtn(layerDOM);
};

__.initialize = function() {
	var layerDOM;
	__.isStockCar = (SETUPS.get('stockcar.context') && SETUPS.get('stockcar.context').toString() === 'true');

	if (!__.isStockCar) {
		__.checkForMoreBtn(jQuery(_options.initialContentAreaSelector));
		layerDOM = jQuery('.nm-layer');
		if (layerDOM && layerDOM.length > 0) {
			__.checkForLayerMoreBtn(jQuery(_options.globalLayerNavigationSelector).parent());
		}
		__.eventBus.on(appEvents.PAGE_LOADED, __.handlerPageLoaded);
		__.eventBus.on(appEvents.LAYER_LOADED, __.handlerLayerLoaded);
		jQuery(window).on('resize', function() {
			__.checkForMoreBtn(jQuery(_options.initialContentAreaSelector));
			__.checkForLayerMoreBtn(jQuery(_options.globalLayerNavigationSelector).parent());
		});
		jQuery('.nm-basket-navigation-item').on("click", function() {
			setTimeout(function() {
				__.checkForMoreBtn(jQuery(_options.initialContentAreaSelector));
				__.checkForLayerMoreBtn(jQuery(_options.globalLayerNavigationSelector).parent());
			}, 20);
		});
		__.initNavToggles();
	}
};

exports.initialize = function(eventEmitter) {
	__.eventBus = eventEmitter;
	__.legacyEventBus = jQuery('body');
	__.initialize();
};

export {exports as navigation};
